import { graphql } from 'gatsby'
import GatsbyHead from '../components/GatsbyHead'
import SampleExampleContent from '../components/SampleExampleContent'

export const query = graphql`
  query ResumeSamples($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
      sections {
        ...PageSection
      }
    }
    industries: allContentfulResumeIndustry(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          id
          title
          description {
            description
          }
          cvCategories {
            title
            slug
            cvs {
              id
              slug
              title
              image {
                childImageSharp {
                  gatsbyImageData(width: 250, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    banner: contentfulComponentTextImage(
      contentful_id: { eq: "6GZZXrTVStvljnjXdb284o" }
      node_locale: { eq: $locale }
    ) {
      ...ComponentTextImage
    }
  }
`

const ResumeSamples = ({ pageContext, data }) => {
  return (
    <SampleExampleContent
      data={data}
      pageContext={pageContext}
      samplePage
    />
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default ResumeSamples
